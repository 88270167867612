
<template>
  <div class="page-notice">
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header
            :total-count="paging.totalCount"
            :no-blank="true"
        ></t-farmon-table-header>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-4">
        <!--begin::as-table-->
        <div class="as-table pt-6">

          <v-data-table
              :headers="fields"
              :items="noticeItem"
              class="elevation-1 farm-data-table"
              no-data-text="No Data"
              hide-default-footer
          >
            <template v-slot:[`item.fieldA`]="{ item }">
              <a href="javascript:;" class="text-dark">{{ item.fieldA }}</a>
            </template>
            <template v-slot:[`item.fieldE`]="{ item }">
              <b-button size="sm" variant="dark" class="rounded-sm" @click="viewNotice(item.noticeNo)">
                <span class="flaticon-eye font-size-xs mr-1"></span>
                <span class="text font-weight-normal">{{i18n.look}}</span>
              </b-button>
            </template>
          </v-data-table>

          <!--begin::pagination-->
          <div v-if="totalPage" class="pagination mt-12">
            <b-pagination-nav
              :number-of-pages="totalPage"
              :link-gen="linkGen"
              use-router
            >
              <template #first-text><i class="fas fa-angle-double-left"></i></template>
              <template #prev-text><i class="fas fa-angle-left"></i></template>
              <template #next-text><i class="fas fa-angle-right"></i></template>
              <template #last-text><i class="fas fa-angle-double-right"></i></template>
              <template #page="{ page, active }">
                <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
                <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
              </template>
            </b-pagination-nav>
          </div>
          <div class="text-right">
            <b-button variant="white" @click="alarmTestCount++"></b-button>
          </div>
          <!--end::pagination-->
        </div>
        <div v-if="alarmTestCount%2 === 0 && alarmTestCount > 4" class="p-10 bg-secondary">
          <h1>알림 전송 테스트 화면</h1>
          <div class="p-5">
            <label>
              NotiId
              <b-button v-b-toggle.collapse-1-inner size="sm">▾</b-button>
              <b-collapse id="collapse-1-inner" class="mt-2">
                <b-card>
                  1	클레임 요청 접수
                  <br>2	클레임 요청 처리
                  <br>3	클레임 요청 완료
                  <br>4	재배관리코멘트
                  <br>5	클레임 요청 접수_new
                  <br>6	클레임 요청 등록_new
                  <br>7	클레임 요청 처리_new
                  <br>8	클레임 요청 완료_new
                  <br>9	일탈 알림
                  <br>10	양액 보충 알림
                  <br>11	양액 보충 알림
                  <br>12	양액 수위 알림
                  <br>13	냉각기 순환펌프 고장 알람
                  <br>14	환경 데이터 확인 불가 알림
                  <br>15	일탈 조치 알림
                </b-card>
              </b-collapse>
            </label>
            <b-form-input v-model="notiId" type="number"></b-form-input>
            <label>mno(현재 본인 mno: {{ $store.state.auth.profile.mno }})</label>
            <b-form-input v-model="mno"></b-form-input>
            <label>kakaoFlag {{ kakaoFlag }}</label>
            <b-form-checkbox v-model="kakaoFlag" switch></b-form-checkbox>
          </div>
          <b-button variant="success" @click="sendPush">알림 전송 테스트</b-button>
        </div>

        <!--end::as-table-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {ACT_GET_MY_NOTICES, ACT_SEND_ALARM_TEST} from '@/core/services/variable';
import {getItems, getPaging, lengthCheck, pagingSequence, timestampToDate} from '@/core/services/funcs';
import {mapGetters} from 'vuex';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "notice",
  components: {
    TFarmonTableHeader
  },
  beforeMount() {
    this.paging.pageNo = (this.$route.query.page || 1);
    this.getNotices();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.notice'), icon: 'fas fa-flag' }]);
    window.scrollTo(0,0);
  },
  watch: {
    '$route'(to){
      this.paging.pageNo = (to.query.page || 1);
      this.getNotices();
    }
  },
  data() {
    return {
      i18n: {
        look: i18n.t('notice.look'),
      },
      fields: [
        { value: 'idx', text: i18n.t('notice.numbering'), width: '80px' },
        { value: 'fieldA', text: i18n.t('notice.type'), width: '15%' },
        { value: 'fieldB', text: i18n.t('notice.title') },
        { value: 'fieldC', text: i18n.t('notice.registrant'), width: '15%' },
        { value: 'fieldD', text: i18n.t('notice.registeredDate'), width: '15%' },
        { value: 'fieldE', text: '', width: '80px' }
      ],
      notices: [],
      paging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      notiId: null,
      mno: null,
      kakaoFlag: true,
      alarmTestCount: 0
    }
  },
  computed: {
    ...mapGetters(['farmId']),
    noticeItem(){
      const items = [];
      this.notices.forEach((notice, idx) => {
        items.push(
            { 'idx': pagingSequence(this.paging, idx), 'fieldA' : notice.noticeType + ' 공지', 'fieldB' : notice.title,
              'fieldC' : notice.memberNm, 'fieldD' : timestampToDate(notice.createDt), 'noticeNo': notice.noticeNo
            },
        )
      });
      return items;
    },
    totalPage() {
      if(this.paging){
        return Math.ceil(this.paging.totalCount / this.paging.pageSize);
      }
      return 0;
    }
  },
  methods: {
    sendPush() {
      const params = {
        notiId: this.notiId,
        mno: this.mno,
        kakaoFlag: this.kakaoFlag
      }
      this.$store.dispatch(ACT_SEND_ALARM_TEST, params)
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getNotices(){
      this.$store.dispatch(ACT_GET_MY_NOTICES, {pageNo: this.paging.pageNo, pageSize: this.paging.pageSize})
      .then(resp => {
        if(lengthCheck(resp)){
          this.notices = getItems(resp);
          this.paging = getPaging(resp);
        }else{
          this.clearNotices();
        }
      })
      .catch(e => {
        console.error(e);
        this.clearNotices();
      });
    },
    clearNotices() {
      this.notices = [];
      this.paging = getPaging();
    },
    viewNotice(noticeNo){
      this.$router.push('notice/' + noticeNo);
    }
  }


};
</script>
